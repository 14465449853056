/*
 * Copyright (C) 2024. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */
import path from 'path'

import Vue from 'vue'
import { BootstrapVue, BVToastPlugin, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

import CProxyDriver from '@amdx/cproxydriver'
import Exhibits from '@amdx/exhibits'
import PowervisorDriver from '@amdx/powervisordriver'
import VolumeCtlDriver from '@amdx/volumectldriver'

import UserManagement from '@amdx/usermanagement'
import CMS from '@amdx/emscms'

import App from './App.vue'
import makeRouter from './router'
import { DriverManager, utils } from '@amdx/emslib'
import KeyCloakManager from './js/keycloakmanager'
import powervisor from '@amdx/powervisorweb'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/_globals.scss'

let router

const initAuth = async () => {
  const scopes = await getScopes()

  const configPath = path.join(
    window.location.pathname,
    process.env.VUE_APP_CONFIG_DIR,
    'keycloak.json'
  )
  const kcres = await fetch(configPath)
  const kcconf = await kcres.json()
  const kcmgnr = new KeyCloakManager(kcconf, scopes)
  Vue.prototype.$keycloakmanager = kcmgnr
  kcmgnr.addLoginCallback(initApp)
  kcmgnr.init()
}

async function getScopes () {
  const configPath = path.join(
    window.location.pathname,
    process.env.VUE_APP_CONFIG_DIR,
    'scopes.json'
  )
  const response = await fetch(configPath)
  return await response.json()
}

function setupCms (config) {
  Vue.use(CMS, {
    config: config.cms,
    keycloakmanager: Vue.prototype.$keycloakmanager
  })
  router.addRoute(CMS.getRoute())
  Vue.cmsPlugin = CMS
}

async function setupPowervisor () {
  const configPath = path.join(
    Vue.prototype.$EMSWEB_BASE_PATH,
    process.env.VUE_APP_CONFIG_DIR,
    'pvconfig.json'
  )
  const appres = await fetch(configPath)
  const appconf = await appres.json()
  Vue.use(powervisor, {
    config: appconf,
    keycloakManager: Vue.prototype.$keycloakmanager
  })
}

async function setupI18n () {
  let configPath = path.join(
    Vue.prototype.$EMSWEB_BASE_PATH,
    process.env.VUE_APP_CONFIG_DIR,
    'ems_i18n.json'
  )
  let response = await fetch(configPath)
  let i18nData = await response.json()

  configPath = path.join(
    Vue.prototype.$EMSWEB_BASE_PATH,
    process.env.VUE_APP_CONFIG_DIR,
    'custom_i18n.json'
  )
  response = await fetch(configPath)
  let customI18nData = null
  try {
    customI18nData = await response.json()
    i18nData = utils.mergeDeep(i18nData, customI18nData)
  } catch (error) {
    console.info('Custom i18n was not defined!')
  }

  let locale = null
  if (localStorage['ems-locale']) {
    locale = localStorage['ems-locale']
  } else {
    locale = navigator.language || navigator.userLanguage
    locale = locale.split('-')[0]
  }
  const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'en',
    messages: i18nData // set locale messages
  })
  return i18n
}

function setupUserManager () {
  Vue.use(UserManagement)
  router.addRoute(UserManagement.getRoute())
  Vue.userPlugin = UserManagement
}

const initApp = async () => {
  const configPath = path.join(
    window.location.pathname,
    process.env.VUE_APP_CONFIG_DIR,
    'config.json'
  )
  let response = await fetch(configPath)
  let config = await response.json()

  Vue.prototype.$EMSWEB_BASE_PATH = config.routerBase
  Vue.prototype.$EMSWEB_DOCS_PATH = config.docsPath

  router = makeRouter(config.routerBase)

  let domain = window.location.protocol + '//' + window.location.hostname
  Vue.prototype.$BROKER_PROXY = domain + config.brokerProxy
  Vue.prototype.$TIMELINE_MONGODB = domain + config.timelineMongoDb
  Vue.prototype.$SUPPORT_EMAIL = config.supportEmail

  Vue.prototype.$EXTERNAL_LINKS = null
  if (config.externalLinks) {
    Vue.prototype.$EXTERNAL_LINKS = config.externalLinks
  }

  Vue.prototype.$ENABLE_POWERVISOR = true
  if (config.enablePower !== undefined) {
    Vue.prototype.$ENABLE_POWERVISOR = config.enablePower
  }
  Vue.prototype.$ENABLE_TIMELINE = true
  if (config.enableTimelines !== undefined) {
    Vue.prototype.$ENABLE_TIMELINE = config.enableTimelines
  }
  Vue.prototype.$ENABLE_DOCS = true
  if (config.enableDocs !== undefined) {
    Vue.prototype.$ENABLE_DOCS = config.enableDocs
  }
  Vue.prototype.$ENABLE_CLIENTS = true
  if (config.enableClients !== undefined) {
    Vue.prototype.$ENABLE_CLIENTS = config.enableClients
  }
  Vue.prototype.$ENABLE_DRIVERS = true
  if (config.enableDrivers !== undefined) {
    Vue.prototype.$ENABLE_DRIVERS = config.enableDrivers
  }
  Vue.prototype.$ENABLE_CPACKER = true
  if (config.enablePackages !== undefined) {
    Vue.prototype.$ENABLE_CPACKER = config.enablePackages
  }

  Vue.config.productionTip = false

  Vue.use(BootstrapVue)
  Vue.use(BVToastPlugin)
  Vue.use(IconsPlugin)
  Vue.use(VueI18n)

  Vue.use(CProxyDriver)
  if (Vue.prototype.$ENABLE_POWERVISOR) {
    Vue.use(PowervisorDriver)
  }

  Vue.use(VolumeCtlDriver)

  // Setup cproxy
  Vue.transferPlugin = null
  if (config.enableTransfer !== undefined && config.enableTransfer) {
    Vue.use(Exhibits, {
      config: config.cms,
      keycloakmanager: Vue.prototype.$keycloakmanager
    })
    router.addRoute(Exhibits.getRoute())
    Vue.transferPlugin = Exhibits
  }

  // Config cms
  Vue.cmsPlugin = null
  setupCms(config)

  // Config powervisor
  if (Vue.prototype.$ENABLE_POWERVISOR) {
    await setupPowervisor()
  }

  // Config user management
  Vue.userPlugin = null
  setupUserManager()

  let i18n = await setupI18n()

  Vue.prototype.$driverManager = new DriverManager(
    Vue.prototype.$BROKER_PROXY,
    config.brokerTimeout,
    Vue.prototype.$keycloakmanager
  )

  Vue.prototype.$VUE_APP_VERSION = require('../package').version

  new Vue({
    i18n,
    router,
    render (h) {
      return h(App)
    }
  }).$mount('#app')
}

initAuth()
