var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"amdx-navbar",attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('b-img',{staticClass:"navbar-logo",attrs:{"src":"./assets/logo-new.svg"}})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-text-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-text-collapse","is-nav":""}},[_c('b-navbar-nav',[(
          _vm.enablePower &&
          _vm.userHasAccess(_vm.scopes.POWER, [_vm.scopeMethods.READ, _vm.scopeMethods.WRITE])
        )?_c('b-nav-item',{attrs:{"to":"/power"}},[_c('b-icon-power'),_vm._v(" "+_vm._s(_vm.$t('ems.nav.power'))+" ")],1):_vm._e(),(
          _vm.enableTimelines &&
          _vm.userHasAccess(_vm.scopes.TIMELINES, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE,
            _vm.scopeMethods.DELETE
          ])
        )?_c('b-nav-item',{attrs:{"to":"/timelines"}},[_c('b-icon-clock'),_vm._v(" "+_vm._s(_vm.$t('ems.nav.timelines'))+" ")],1):_vm._e(),(
          _vm.transferPlugin &&
          _vm.userHasAccess(_vm.scopes.TRANSFER, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE
          ])
        )?_c('plugin-nav-element',{attrs:{"plugin":_vm.transferPlugin}}):_vm._e(),(
          _vm.cmsPlugin &&
          _vm.userHasAccess(_vm.scopes.CMS, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE,
            _vm.scopeMethods.DELETE
          ])
        )?_c('plugin-nav-element',{attrs:{"plugin":_vm.cmsPlugin}}):_vm._e(),_vm._l((_vm.externalLinks),function(link,i){return _c('b-nav-item',{key:'link' + i,attrs:{"href":link.url,"target":"_blank"}},[_c('b-icon',{attrs:{"icon":link.icon}}),_vm._v(" "+_vm._s(_vm.$t(link.i18nId))+" ")],1)}),(
          _vm.enablePackages &&
          _vm.userHasAccess(_vm.scopes.PACKAGES, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE
          ])
        )?_c('b-nav-item',{attrs:{"to":"/packages"}},[_c('b-icon-file-zip-fill'),_vm._v(" Packages ")],1):_vm._e(),(
          (_vm.enableClients || _vm.enableDrivers) &&
          _vm.userHasAccess(_vm.scopes.CLIENTS, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE,
            _vm.scopeMethods.DELETE
          ])
        )?_c('b-nav-item-dropdown',{attrs:{"toggle-class":_vm.$route.name &&
          (_vm.$route.name.includes('clientControlOverview') ||
            _vm.$route.name.includes('clientControl'))
            ? 'active'
            : null},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-display'),_vm._v(" "+_vm._s(_vm.$t('ems.nav.clients'))+" ")]},proxy:true}],null,false,113154073)},[(_vm.enableClients)?_c('b-dropdown-item',{staticClass:"my-2",attrs:{"to":"/clients/control"}},[_c('b-icon-Terminal'),_vm._v(" "+_vm._s(_vm.$t('ems.nav.control'))+" ")],1):_vm._e(),(_vm.enableDrivers)?_c('b-dropdown-item',{staticClass:"my-2",attrs:{"to":"/clients/drivers"}},[_c('b-icon-box-seam',{staticClass:"p-0"}),_vm._v(" "+_vm._s(_vm.$t('ems.nav.drivers'))+" ")],1):_vm._e()],1):_vm._e(),(
          _vm.userPlugin &&
          _vm.userHasAccess(_vm.scopes.USERS, [
            _vm.scopeMethods.READ,
            _vm.scopeMethods.WRITE,
            _vm.scopeMethods.DELETE
          ])
        )?_c('plugin-nav-element',{attrs:{"plugin":_vm.userPlugin}}):_vm._e(),(_vm.enableDocs)?_c('b-nav-item',{attrs:{"to":"/docs"}},[_c('b-icon-question-circle',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t('ems.nav.docs'))+" ")],1):_vm._e()],2),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([(_vm.userInfo)?{key:"button-content",fn:function(){return [_c('b-icon-person-circle'),_vm._v(" "+_vm._s(_vm.userInfo.preferred_username)+" ")]},proxy:true}:null],null,true)},[_c('b-dropdown-item',{staticClass:"my-2",attrs:{"to":"/profile"}},[_c('b-icon-person-lines-fill',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t('ems.profile.profile'))+" ")],1),_c('b-dropdown-item',{staticClass:"my-2",on:{"click":_vm.logout}},[_c('b-icon-arrow-bar-right',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t('ems.profile.signOut'))+" ")],1),_c('b-dropdown-divider'),_c('b-dropdown-group',{attrs:{"header":_vm.$t('ems.profile.uiLanguage')}},_vm._l((_vm.locales),function(locale,i){return _c('b-dropdown-item',{key:i,staticClass:"my-2",attrs:{"disabled":locale === _vm.currentlocale},on:{"click":function($event){return _vm.changeLocale(locale)}}},[_vm._v(" "+_vm._s(locale.toUpperCase())+" ")])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }