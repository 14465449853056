/*
 * Copyright (C) 2024. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function userHasAccess (module, methods) {
  return Vue.prototype.$keycloakmanager.userHasAccess(module, methods, true)
}

function makeRouter (base) {
  const scopes = Vue.prototype.$keycloakmanager.scopes
  const scopeMethods = Vue.prototype.$keycloakmanager.scopeMethods
  const router = new Router({
    mode: 'history',
    base: base,
    linkActiveClass: 'active', // Replacing default class with bootstrap active
    scrollBehavior () {
      return {
        x: 0,
        y: 0
      }
    },
    routes: [
      {
        path: '/',
        component () {
          return import(
            /* webpackChunkName: "clientTypes" */ './views/Home.vue'
          )
        }
      },
      {
        path: '/packages',
        name: 'packages',
        component () {
          return import(/* webpackChunkName: "cpacker" */ './views/CPacker.vue')
        },
        meta: {
          scope: scopes.PACKAGES,
          methods: [scopeMethods.READ, scopeMethods.WRITE]
        }
      },
      {
        path: '/clients/control',
        name: 'clientControlOverview',
        component () {
          return import(/* webpackChunkName: "control" */ './views/Clients.vue')
        },
        meta: {
          scope: scopes.CLIENTS,
          methods: [scopeMethods.READ, scopeMethods.WRITE, scopeMethods.DELETE]
        }
      },
      {
        path: '/clients/control/:client_id',
        name: 'clientControl',
        component () {
          return import(
            /* webpackChunkName: "control" */ './views/ControlClient.vue'
          )
        },
        meta: {
          scope: scopes.CLIENTS,
          methods: [scopeMethods.WRITE, scopeMethods.DELETE]
        }
      },
      {
        path: '/timelines',
        name: 'timelines',
        component () {
          return import(
            /* webpackChunkName: "timeline" */ './views/Timelines.vue'
          )
        },
        meta: {
          scope: scopes.TIMELINES,
          methods: [scopeMethods.READ, scopeMethods.WRITE, scopeMethods.DELETE]
        }
      },
      {
        path: '/timelines/:cal_uuid/:rev',
        name: 'timelineDetails',
        component () {
          return import(
            /* webpackChunkName: "timeline" */ './views/TimelineDetails.vue'
          )
        },
        meta: {
          scope: scopes.TIMELINES,
          methods: [scopeMethods.READ, scopeMethods.WRITE, scopeMethods.DELETE]
        }
      },
      {
        path: '/power',
        name: 'power',
        component () {
          return import(/* webpackChunkName: "clients" */ './views/Power.vue')
        },
        meta: {
          scope: scopes.POWER,
          methods: [scopeMethods.READ, scopeMethods.WRITE]
        }
      },
      {
        path: '/clients/drivers',
        name: 'drivers',
        component () {
          return import(/* webpackChunkName: "drivers" */ './views/Drivers.vue')
        },
        meta: {
          scope: scopes.CLIENTS,
          methods: [scopeMethods.READ]
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component () {
          return import(
            /* webpackChunkName: "profile" */ './views/UserProfile.vue'
          )
        }
      },
      {
        path: '/docs',
        name: 'docs',
        component () {
          return import(/* webpackChunkName: "docs" */ './views/Docs.vue')
        }
      },
      {
        path: '/notfound',
        component () {
          return import(/* webpackChunkName: "notfound" */ './views/404.vue')
        }
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component () {
          return import(/* webpackChunkName: "forbidden" */ './views/403.vue')
        }
      },
      {
        path: '*',
        component () {
          return import(/* webpackChunkName: "notFound" */ './views/404.vue')
        }
      }
    ]
  })

  router.beforeEach(async (to, from, next) => {
    if ('scope' in to.meta && 'methods' in to.meta) {
      if (userHasAccess(to.meta.scope, to.meta.methods)) {
        return next()
      } else {
        return next({
          name: 'forbidden',
          query: {
            scope: to.meta.scope,
            methods: to.meta.methods.toString()
          }
        })
      }
    } else {
      return next()
    }
  })

  return router
}

export default makeRouter
