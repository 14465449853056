/*
 * Copyright (C) 2024. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import Keycloak from 'keycloak-js'
import { utils } from '@amdx/emslib'

export default class KeyCloakManager {
  constructor (config = null, scopes = null) {
    this._keyCloak = null
    this._config = config
    this._loginCallbacks = []
    this._userScopes = []
    this._emsScopes = {}
    if (scopes) {
      this._emsScopes = scopes
    } else {
      console.warn('No scopes were defined!')
    }

    if (config) {
      this._keyCloak = new Keycloak(config)

      this._keyCloak.onTokenExpired = () => {
        this._keyCloak
          .updateToken(1)
          .then(() => {
            // console.debug('successfully get a new token', this._keyCloak.token)
          })
          .catch(() => {
            console.debug(
              'Failed to refresh the token, or the session has expired'
            )
            this._keyCloak.logout()
          })
      }

      this._keyCloak.onAuthLogout = () => {
        console.info('Logged out.')
      }

      this._keyCloak.onAuthSuccess = async () => {
        console.info('Logged in.')
        const data = await utils.request(
          'GET',
          '/api/v1/extauth/token/introspect',
          null,
          null,
          'application/json',
          this.token
        )
        this._userScopes = data.scopes
        console.info('Queried introspect.')
        for (let c of this._loginCallbacks) {
          c()
        }
      }
    }
  }

  get scopes () {
    return {
      POWER: 'power',
      TIMELINES: 'timelines',
      TRANSFER: 'transfer',
      CMS: 'cms',
      USERS: 'users',
      PACKAGES: 'packages',
      CLIENTS: 'clients'
    }
  }

  get scopeMethods () {
    return {
      READ: 'read',
      WRITE: 'write',
      DELETE: 'delete'
    }
  }

  get keyCloak () {
    return this._keyCloak
  }

  get isConfigured () {
    return !!this._keyCloak
  }

  get token () {
    this.isTokenExpired(5)
    return this._keyCloak.token
  }

  init () {
    if (!this._config) {
      for (let c of this._loginCallbacks) {
        c()
      }
      return
    }
    this._keyCloak
      .init({ onLoad: this._config.onLoad })
      .then((authenticated) => {
        console.info(authenticated ? 'Authenticated' : 'not authenticated')
      })
      .catch((err) => {
        console.error(err)
      })
  }

  _gatherScopes (module, methods) {
    if (module in this._emsScopes) {
      const moduleScopes = this._emsScopes[module]
      let scopes = []
      for (let method of methods) {
        if (method in moduleScopes) {
          scopes = scopes.concat(moduleScopes[method])
        } else {
          return null
        }
      }
      return scopes
    }
    return null
  }

  loadUserInfo () {
    return this._keyCloak.loadUserInfo()
  }

  loadUserProfile () {
    return this._keyCloak.loadUserProfile()
  }

  logout () {
    this._keyCloak.logout()
  }

  updateToken (minValidity = null) {
    return this._keyCloak.updateToken(minValidity)
  }

  isTokenExpired (minValidity = null) {
    return this._keyCloak.isTokenExpired(minValidity)
  }

  clearToken () {
    return this._keyCloak.clearToken()
  }

  addLoginCallback (c) {
    this._loginCallbacks.push(c)
  }

  removeLoginCallback (c) {
    let index = this._loginCallbacks.indexOf(c)
    if (index > -1) {
      this._loginCallbacks.splice(index, 1)
    }
  }

  getTokenHeader () {
    let header = {
      'Content-Type': 'application/json'
    }
    if (this._keyCloak) {
      header.Authorization = 'Bearer ' + this.token
    }
    return header
  }

  getMissingScopes (module, methods) {
    const emsScopes = this._gatherScopes(module, methods)
    if (emsScopes == null) {
      console.error(
        'Scopes for module ' + module + ' or method could not be found!'
      )
      return []
    }
    return emsScopes.filter((s) => !this._userScopes.includes(s.toString()))
  }

  userHasAccess (module, methods, isPermissive = false) {
    const emsScopes = this._gatherScopes(module, methods)
    if (emsScopes == null) {
      console.error(
        'Scopes for module ' + module + ' or method could not be found!'
      )
      return false
    }
    let hasAccess = false
    if (isPermissive) {
      hasAccess = emsScopes.some((s) => this._userScopes.includes(s.toString()))
    } else {
      hasAccess = emsScopes.every((s) =>
        this._userScopes.includes(s.toString())
      )
    }
    if (!hasAccess) {
      console.debug(
        'User has no access to ' +
          module +
          ' with methods ' +
          methods.toString() +
          ' !'
      )
    }
    return hasAccess
  }
}
